<template>
    <section class="trip-restaurant" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Рейсы ресторана"</h2>
        <div class="trip-restaurant__base" v-if="modeId === 'base'">
            <div class="trip-restaurant__box box">
                <table class="trip-restaurant__table trip-restaurant__table_striped trip-restaurant__table_hover">
                    <colgroup>
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_title">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_delivery_duration">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_value">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_orders_count">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_trip_count">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_orders_avg_in_trip">
                    </colgroup>
                    <thead>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_head">
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head trip-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('title')"
                            >
                                <span class="trip-restaurant__table-value">Источник</span>
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'title' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'title' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head trip-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery_duration')"
                            >
                                <span class="trip-restaurant__table-value">Среднее время</span>
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'delivery_duration' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'delivery_duration' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head trip-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('value')"
                            >
                                <span class="trip-restaurant__table-value">Сумма</span>
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'value' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'value' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head trip-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('orders_count')"
                            >
                                <span class="trip-restaurant__table-value">Заказы</span>
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'orders_count' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'orders_count' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head trip-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('trip_count')"
                            >
                                <span class="trip-restaurant__table-value">Рейсы</span>
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'trip_count' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'trip_count' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head trip-restaurant__table-cell_sortable"
                                scope="col"
                                @click="setSorting('orders_avg_in_trip')"
                            >
                                <span class="trip-restaurant__table-value">Заказов на рейс</span>
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'orders_avg_in_trip' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-restaurant__table-value trip-restaurant__table-value_icon"
                                    v-if="sortings.field === 'orders_avg_in_trip' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_body trip-restaurant__table-row_pinned"
                            v-if="reportTripCouriers.length > 1"
                        >
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_title">Итого</td>
                            <td class="trip-restaurant__table-cell">
                                {{reportTripRestaurant.total.delivery_duration | durationFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{reportTripRestaurant.total.value | currencyFloatFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{reportTripRestaurant.total.orders_count | intFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{reportTripRestaurant.total.trip_count | intFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{reportTripRestaurant.total.orders_avg_in_trip | floatFormat}}
                            </td>
                        </tr>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_body"
                            v-for="courier in reportTripCouriers"
                            :key="courier.id"
                        >
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_title">{{courier.title}}</td>
                            <td class="trip-restaurant__table-cell">
                                {{courier.delivery_duration | durationFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{courier.value | currencyFloatFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{courier.orders_count | intFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{courier.trip_count | intFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell">
                                {{courier.orders_avg_in_trip | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="trip-restaurant__compare-other-period" v-else-if="modeId === 'compareOtherPeriod' && compareTrip">
            <div class="trip-restaurant__box box">
                <table class="trip-restaurant__table">
                    <caption class="trip-restaurant__table-caption">Итого</caption>
                    <colgroup>
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_title">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_delivery_duration">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_value">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_orders_count">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_trip_count">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_orders_avg_in_trip">
                    </colgroup>
                    <thead>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_head">
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Среднее время</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Сумма</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Заказы</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Рейсы</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Заказов на рейс</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_body">
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body trip-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{reportTripRestaurant.total.delivery_duration | durationFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value"
                                    :primaryValue="reportTripRestaurant.total.delivery_duration"
                                    :compareValue="compareTripRestaurant.total.delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{reportTripRestaurant.total.value | currencyFloatFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value"
                                    :primaryValue="reportTripRestaurant.total.value"
                                    :compareValue="compareTripRestaurant.total.value"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{reportTripRestaurant.total.orders_count | intFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value"
                                    :primaryValue="reportTripRestaurant.total.orders_count"
                                    :compareValue="compareTripRestaurant.total.orders_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{reportTripRestaurant.total.trip_count | intFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value"
                                    :primaryValue="reportTripRestaurant.total.trip_count"
                                    :compareValue="compareTripRestaurant.total.trip_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{reportTripRestaurant.total.orders_avg_in_trip | floatFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value"
                                    :primaryValue="reportTripRestaurant.total.orders_avg_in_trip"
                                    :compareValue="compareTripRestaurant.total.orders_avg_in_trip"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_body">
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body trip-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                {{compareTripRestaurant.total.delivery_duration | durationFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                {{compareTripRestaurant.total.value | currencyFloatFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                {{compareTripRestaurant.total.orders_count | intFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                {{compareTripRestaurant.total.trip_count | intFormat}}
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                {{compareTripRestaurant.total.orders_avg_in_trip | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="trip-restaurant__box box"
                v-for="courier in reportTripCouriers"
                :key="courier.id"
            >
                <table class="trip-restaurant__table">
                    <caption class="trip-restaurant__table-caption">{{courier.title}}</caption>
                    <colgroup>
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_title">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_delivery_duration">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_value">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_orders_count">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_trip_count">
                        <col class="trip-restaurant__table-column trip-restaurant__table-column_orders_avg_in_trip">
                    </colgroup>
                    <thead>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_head">
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Среднее время</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Сумма</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Заказы</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Рейсы</th>
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_head" scope="col">Заказов на рейс</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_body">
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body trip-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{courier.delivery_duration | durationFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value" v-if="findCourier(compareTripRestaurant, courier.id)"
                                    :primaryValue="courier.delivery_duration"
                                    :compareValue="findCourier(compareTripRestaurant, courier.id).delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{courier.value | currencyFloatFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value" v-if="findCourier(compareTripRestaurant, courier.id)"
                                    :primaryValue="courier.value"
                                    :compareValue="findCourier(compareTripRestaurant, courier.id).value"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{courier.orders_count | intFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value" v-if="findCourier(compareTripRestaurant, courier.id)"
                                    :primaryValue="courier.orders_count"
                                    :compareValue="findCourier(compareTripRestaurant, courier.id).orders_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{courier.trip_count | intFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value" v-if="findCourier(compareTripRestaurant, courier.id)"
                                    :primaryValue="courier.trip_count"
                                    :compareValue="findCourier(compareTripRestaurant, courier.id).trip_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <span class="trip-restaurant__table-value">
                                    {{courier.orders_avg_in_trip | floatFormat}}
                                </span>
                                <difference class="trip-restaurant__table-value" v-if="findCourier(compareTripRestaurant, courier.id)"
                                    :primaryValue="courier.orders_avg_in_trip"
                                    :compareValue="findCourier(compareTripRestaurant, courier.id).orders_avg_in_trip"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="trip-restaurant__table-row trip-restaurant__table-row_body">
                            <th class="trip-restaurant__table-cell trip-restaurant__table-cell_body" scope="row">
                                {{compareDaterange | daterangeText}}
                            </th>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <template v-if="findCourier(compareTripRestaurant, courier.id)">
                                    {{findCourier(compareTripRestaurant, courier.id).delivery_duration | durationFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <template v-if="findCourier(compareTripRestaurant, courier.id)">
                                    {{findCourier(compareTripRestaurant, courier.id).value | currencyFloatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <template v-if="findCourier(compareTripRestaurant, courier.id)">
                                    {{findCourier(compareTripRestaurant, courier.id).orders_count | intFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <template v-if="findCourier(compareTripRestaurant, courier.id)">
                                    {{findCourier(compareTripRestaurant, courier.id).trip_count | intFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-restaurant__table-cell trip-restaurant__table-cell_body">
                                <template v-if="findCourier(compareTripRestaurant, courier.id)">
                                    {{findCourier(compareTripRestaurant, courier.id).orders_avg_in_trip | floatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import Difference from "@/components/Difference";

    export default {
        name: "TripRestaurant",
        components: {
            Difference
        },
        props: {
            id: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            modes: {
                type: Array,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            reportTrip: {
                required: true
            },
            compareTrip: {
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        data() {
            return {
                sortings: {
                    field: "title",
                    direction: "ASC"
                }
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
            }),
            reportTripRestaurant() {
                return this.reportTrip?.restaurants?.find(({ id }) => id === this.id);
            },
            reportTripCouriers() {
                return this.reportTripRestaurant?.couriers;
            },
            reportTripCouriersSorted() {
                return this.reportTripCouriers?.sort((courier1, courier2) => {
                    if (this.sortings.field === "title") {
                        return compareStrings(
                            courier1.title,
                            courier2.title,
                            this.sortings.direction
                        );
                    }

                    return compareNumbers(
                        courier1[this.sortings.field],
                        courier2[this.sortings.field],
                        this.sortings.direction
                    );
                });
            },
            compareTripRestaurant() {
                return this.compareTrip?.restaurants?.find(({ id }) => id === this.id);
            },
            compareTripCouriers() {
                return this.compareTripRestaurant?.couriers;
            }
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "toggleReportTotalLink",
                "toggleRestaurantsFilter"
            ]),
            findCourier(restaurant, courierId) {
                return restaurant?.couriers?.find(({ id }) => id === courierId);
            },
            setSorting(field) {
                if (this.sortings.field === field) {
                    this.sortings.direction = this.sortings.direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sortings.field = field
                }
            }
        },
        filters: {
            daterangeText
        },
        created() {
            if (!this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({
                    name: "Error401",
                    params: {
                        requestedResource: `Ресторан ${this.id}`
                    }
                });
            } else {

                if (this.userRestaurants?.ids?.length > 1 && !this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(true);

                    this.toggleRestaurantsFilter(true);
                }

                let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                    this.setTitle(`${this.title} "${restaurantTitle}"`);

                    this.toggleRestaurantsFilter(false);
                }
            }
        }
    }
</script>

<style lang="scss">
    .trip-restaurant__box {
        padding: 16px;
        overflow-x: auto;

        & + & {
            margin-top: 15px;
        }
    }
    .trip-restaurant__table {
        width: 100%;
    }
    .trip-restaurant__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .trip-restaurant__table-column {
        &_title {
            width: 24%;
        }
        &_delivery_duration {
            width: 16%;
        }
        &_value {
            width: 20%;
        }
        &_orders_count {
            width: 12%;
        }
        &_trip_count {
            width: 12%;
        }
        &_orders_avg_in_trip {
            width: 16%;
        }
    }
    .trip-restaurant__table-row {
        &_body {
            .trip-restaurant__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .trip-restaurant__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .trip-restaurant__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_head {
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .trip-restaurant__table-value {
        line-height: 1;
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
